<template>
  <v-app style="max-width:425px; margin-left:auto; margin-right:auto">

    <div>
        <v-row justify="center">
          <v-dialog v-model="ageLimitModal" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break: normal">
               {{$t("Warning_Message.Invalid_Account_YouShould_Be_Above18To_Have_An_Account")}}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  depressed
                  @click="closeAgeLimitModal"
                  style="background-color: #1467BF;"
                >
                   {{$t("Warning_Message.Ok")}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div>
        <v-row justify="center">
          <v-dialog v-model="invalidFormatFlag" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break: normal">
               {{$t("Warning_Message.DOB_Invalid_Format")}}
               <!-- Date of Birth is in invalid format (Must be in YYYY-MM-DD format) -->
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  depressed
                  @click="invalidFormatPopup"
                  style="background-color: #1467BF;"
                >
                   {{$t("Warning_Message.Ok")}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

    <div class="container-fluid OTPSignupMainDiv" style="margin:20px;">
      <div style="position:absolute; margin-left: 10%;" class="LanguageSelect" align="right">
        <v-select
          style="width:20%;"
          :items="LanguageList"
          v-model="Language"
          item-text="item-text"
          item-value="item-value"
          @change="changeLanguage"
          >
          <template v-slot:selection="{ item, index }">
            <img :src="item.image">
         </template>
        </v-select>
      </div>
      <div>
        <h1 style="text-align:left;margin-top:10px;">{{ $t("Login.Signup.Get_Started") }}</h1>
        <p style ="font-size:110%;text-align:left;margin-top:16px;">{{ $t("Login.Signup.Please_Sign_Up") }}</p>
      </div>
      <div style="margin-top:48px; overflow:scroll; overflow-x: hidden !important; height:70vh;" align="left">
        <div class="Name">
          <p class="font_size_12_400">{{ $t("Login.Signup.Full_Name") }}</p>
          <img src="@/assets/Name_icon.png" align="left" style="width:26px ;height:26px" />
          <v-col cols="12" >
            <v-text-field type="text" background-color="#F4F6FA" style="margin-top:-30px; margin-left: 16px;" v-model="fullName">
             </v-text-field>
          </v-col>
        </div>

        <div class="MobileNumber">
          <p class="font_size_12_400">{{ $t("Login.Signup.Mobile_Number") }}</p>
          <img src="https://s3iconimages.mymedicine.com.mm/Mobile_Number.svg" align="left" style="width:26px ;height:26px;" />
          <v-col cols="12" >
            <v-text-field type='number' prefix="+95" background-color="#F4F6FA" style="margin-top:-30px; margin-left: 16px;" v-model="mobileNumber" :rules="$store.state.locale == 'en' ? mobileNumberRules : mobileNumberRulesMM" placeholder="09*********">
            </v-text-field>
          </v-col>
        </div>

        <div class="Email">
          <p class="font_size_12_400">{{ $t("Login.Signup.Email") }}</p>
          <img src="@/assets/Email.png" align="left" style="width:26px ;height:26px" />
          <v-col cols="12" >
            <v-text-field type='email' background-color="#F4F6FA" style="margin-top:-30px; margin-left: 16px;" v-model="email">
            </v-text-field>
          </v-col>
        </div>

        <div class="NRC">
          <p class="font_size_12_400">{{ $t("Login.Signup.NRC") }}</p>
          <img src="@/assets/NRC.png" align="left" style="width:26px ;height:26px" />
          <v-col cols="12" >
            <v-text-field type='text' background-color="#F4F6FA" style="margin-top:-30px; margin-left: 16px;" v-model="nrc">
            </v-text-field>
          </v-col>
        </div>

        <div class="NRC">
          <p class="font_size_12_400">{{ $t("Login.Signup.Gender") }}</p>
          <img src="@/assets/NRC.png" align="left" style="width:26px ;height:26px" />
          <v-col cols="12" >
            <v-select
             v-if="$i18n.locale=='en'"
              :items="genderList"
              return-object
              v-model="gender"
              style="margin-top:-30px; margin-left: 16px;"
              :rules="genderRules"
              background-color="#F4F6FA"
                 item-text = "en_text"
              >
            </v-select>
              <v-select
             v-if="$i18n.locale=='mm'"
              :items="genderList"
              v-model="gender"
              style="margin-top:-30px; margin-left: 16px;"
              :rules="genderRulesMM"
              background-color="#F4F6FA"
                 item-text = "mm_text"
                 return-object
              >
            </v-select>
          </v-col>
        </div>

        <div class="Date_of_birth">
          <p class="font_size_12_400">{{ $t("Login.Signup.Date_Of_Birth") }}</p>
          <div style="display: flex; flex-direction: row">
            <div style="width: 100%">
               <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="90%"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateOfBirth"
             background-color="#F4F6FA"
             prepend-icon="mdi-calendar"
            v-bind="attrs"
            v-on="on"
            style="border-radius:8px;"
            :rules="dobRule"
            @change="get_age(dateOfBirth)"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="dateOfBirth"
          :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
          min="1900-01-01"
          @change="get_age(dateOfBirth),menu= false"
          full-width
        ></v-date-picker>
      </v-menu>
              <!-- <date-picker v-model="dateOfBirth"  :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
          min="1900-01-01" valueType="format" style="width: 100%;"></date-picker> -->
            </div>
          </div>
        </div>


        <div style="margin-top:10px;font-size:90%; display:flex; flex-direction: row;" v-if="$i18n.language=='en'">
          <input type="checkbox" style="width:25px; height:16px;" v-model="t_c" /> &nbsp; {{ $t("Login.Signup.Agree") }}<a href="/customer/termsandconditions" style="text-decoration: none;">{{ $t("Login.Signup.Terms_And_Conditions") }}</a>
        </div>

        <div style="margin-top:10px;font-size:90%; display:flex; flex-direction: row;" v-else>
          <input type="checkbox" style="width:25px; height:16px;" v-model="t_c" /> &nbsp; <a href="/customer/termsandconditions" style="text-decoration: none;">{{ $t("Login.Signup.Agree") }}</a> &nbsp;{{ $t("Login.Signup.Terms_And_Conditions") }}
        </div>

        <div style="margin-top:10px;">
          <v-btn elevation="" class ="signup" color="primary" large style="width: 100%; background-color: #1467BF;" @click="sendOTP()" :disabled="isButtonClicked || !(fullName.trim() != '' && mobileNumber != '' && dateOfBirth != '' && email !='' && nrc !='' && mobileNumber.length >= 7 && mobileNumber.length <=11 && dateOfBirth != '' && email !='' && nrc !='' && gender)">
            {{ $t("Login.Signup.Signup") }}
          </v-btn>
        </div>
        <div style="margin-top:30px;font-size:105%;" align="center">
          {{ $t("Login.Signup.Already_A_User") }} <a href="./login">{{ $t("Login.Signup.Login") }}</a>
        </div>
      </div>
    </div>

    <div>
      <v-row justify="center">
        <v-dialog v-model="userRegistrationModel" max-width="290">
          <v-card>
            <v-card-title style="word-break:normal">
              {{$t("Warning_Message.User_Registration_Failed")}}
            </v-card-title>
            <v-card-text
            ></v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                align="center"
                depressed
                color="primary"
                @click="closeUserRegistrationModel"
                style="background-color: #1467BF;"
                >
                {{$t("Warning_Message.Ok")}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>

    <div>
      <v-row justify="center">
        <v-dialog v-model="TermsError" max-width="290">
          <v-card>
            <v-card-title style="word-break:normal">
              {{$t("Warning_Message.Please_Agree_Terms_And_condition")}}
            </v-card-title>
            <v-card-text
            ></v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                align="center"
                depressed
                color="primary"
                @click="closeTermsError"
                style="background-color: #1467BF;"
                >
                {{$t("Warning_Message.Ok")}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>

    <div>
      <v-row justify="center">
        <v-dialog v-model="phoneNumberError" max-width="290">
          <v-card>
            <v-card-title style="word-break:normal">
              {{$t("Warning_Message.Please_Enter_Your_Number_With0In_The_Beginning")}}
            </v-card-title>
            <v-card-text
            ></v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                align="center"
                depressed
                color="primary"
                @click="phoneNumberError = false"
                style="background-color: #1467BF;"
                >
                {{$t("Warning_Message.Ok")}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>

    <div>
      <v-row justify="center">
        <v-dialog v-model="alreadyRegisteredUser" persistent max-width="290">
          <v-card>
            <v-card-title style="word-break:normal">
              {{$t("Warning_Message.User_Already_Registered")}}
            </v-card-title>
            <v-card-text
            ></v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <div  style="display:flex; flex-direction:row; justify-content: space-between; width:100%">
                <div>
                  <v-btn class="primary" text @click="redirectToLoginPage" style="background-color: #1467BF;">
                     {{$t("Warning_Message.Login")}}
                  </v-btn>
                </div>
                <div>
                  <v-btn class="primary" text @click="alreadyRegisteredUser = false;" style="background-color: #EB5757;">
                   {{$t("Warning_Message.Cancel")}}
                  </v-btn>
                </div>
              </div>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </v-app>
</template>

<script>
import axios from 'axios';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

  export default {
    components: { DatePicker },
    data() {
      return {
        fullName:"",
        ageLimitModal:"",
        menu:false,
        mobileNumber:"",
        dateOfBirth:"",
        email:"",
        nrc: "",
        LanguageList: [{"item-text":"English","item-value":"en","image":"https://s3iconimages.mymedicine.com.mm/usa_flag.svg"},{"item-text":"Burmese","item-value":"mm","image": "https://s3iconimages.mymedicine.com.mm/myanmar_flag.svg"}],
        Language: "",
        t_c: "",
        genderList : [{"key":"male","mm_text":"ကျား","en_text":"Male"},{"key":"female","mm_text":"မ","en_text":"Female"},{"key":"other","mm_text":"အခြား","en_text":"Other"}],
        genderRules : [v => !!v || 'Gender is required'],
        genderRulesMM : [v => !!v || 'ကျား/မထည့်သွင်းရန် လိုအပ်ပါသည်။'],
        gender: "",
        TermsError : false,
        phoneNumberError: false,
        userRegistrationModel : false,
        alreadyRegisteredUser : false,
        mobileNumberRules: [
                        v => !!v || 'Mobile Number is required',
                        v => (v && v.length >=7 && v.length <= 11) || 'Mobile Number should be between 7 to 11 numbers',
                        v => v > 0 || 'Mobile number cannot be negative',
                        ],
        mobileNumberRulesMM : [
                        v => !!v || 'ဖုန်းနံပါတ်ထည့်သွင်းရန် လိုအပ်ပါသည်။',
                        v => (v && v.length >=7 && v.length <= 11) || 'ဖုန်းနံပါတ်၏ ဂဏန်းအရေအတွက်မှာ ၇ လုံးမှ ၁၁ လုံးဖြစ်ရပါမည်။',
                        v => v > 0 || 'Mobile number cannot be negative',
                        ],

        translation_dob: "Date Of Birth",
        dobRule : [
          v => !!v || 'DOB is required',
          v => /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/.test(v) || 'DOB must be in format "YYYY-MM-DD"',
        ],
        isButtonClicked: false,
        invalidFormatFlag : false,
      };
    },
    methods:{
      redirectToLoginPage() {
        this.$router.push({
          name: "Login",
        });
      },
      closeAgeLimitModal(){
        this.ageLimitModal = false
      },
      invalidFormatPopup(){
        this.invalidFormatFlag = false;
      },
      get_age(dateString) {
        if(!(/^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/.test(dateString))){
          console.log("Date not in correct format");
          this.dateOfBirth = "";
          this.invalidFormatFlag = true;
        }
        else{
          this.invalidFormatFlag = false;
        }

        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        if(age < 18){
           this.ageLimitModal = true;
           this.dateOfBirth = "";
        }
  	  else{
  		  this.ageLimitNotInRange = false;
  	  }
    },
      changeLanguage(){
        this.$i18n.locale = this.Language;
        this.$store.dispatch("changeLocale", this.$i18n.locale);
      },
      sendOTP(){
        if(this.t_c == true)
        {
          //call backend to verify if phone number exists
          this.isButtonClicked = true;
          if(this.mobileNumber[0] == "0")
          {
            var phoneNumberCheckData = {
              phoneNumber: this.mobileNumber
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/checkIfPhoneNumberExists",phoneNumberCheckData)
            .then((checkIfPhoneNumberExistsResponse) =>{
              var otpBody = {
                phoneNumber: this.mobileNumber,
                email: this.email,
                newUserFlag: true
              };
              if(checkIfPhoneNumberExistsResponse.data.data == "NotExists"){
                otpBody.newUserFlag = true;
                otpBody.email = this.email;
              }
              else{
                otpBody.newUserFlag = false;
              }
              axios.post(process.env.VUE_APP_BACKEND_URL+"/sendOTP",otpBody)
              .then((sendOTPResponse) =>{
              })
              .catch((sendOTPError) =>{
              });
              this.$router.push({
                name: 'OTPSignup',
                params: {
                  'phone_number': this.mobileNumber,
                  'fullName': this.fullName.trim(),
                  'dateOfBirth': this.dateOfBirth,
                  'email' : this.email,
                  'nrc' : this.nrc,
                  'gender': this.gender.en_text,
                  'new_user' : true
                }
              });
            })
            .catch((checkIfPhoneNumberExistsError)=>{
              this.isButtonClicked = false;
              if(checkIfPhoneNumberExistsError.response.status == 305){
                this.alreadyRegisteredUser = true;
              }
            });
          }
          else
          {
            this.phoneNumberError = true;
            this.isButtonClicked = false;
          }
        }
        else
        {
          this.TermsError = true;
          this.isButtonClicked = false;
        }
      },
      closeUserRegistrationModel(){
        this.userRegistrationModel = false;
      },
      closeTermsError(){
        this.TermsError = false;
      },
       closeAlreadyRegisteredModel(){
        this.alreadyRegisteredUser = false;
        // this.$router.push({
        //   name : "Login"
        // })
      },
    },
    mounted()
    {
      this.$i18n.locale = this.$store.state.locale != "" && this.$store.state.locale ? this.$store.state.locale : 'mm';
      this.Language = this.$i18n.locale;
      if(this.$i18n.locale == "mm")
      {
        this.translation_dob = "မွေးနေ့"
      }
      else
      {
        this.translation_dob = "Date Of Birth"
      }
      this.currentCustomer = this.$cookies.get("customerToken");
      if(this.currentCustomer){
        this.$router.push({
            path: '/customer/home'
        });
      }
    }
  };
</script>

<style scoped>
/* .v-picker--full-width {
  height: 500px;
} */
.font_size_12_400{
  font-size: 14px;
  font-weight:  400;
}
.mx-input{
  height: 40px !important;
    border-bottom: 1px solid #ccc !important;
    border-radius: none!important;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
}
/* @media (min-width: 800px){
  .OTPSignupMainDiv{
   width: 800px;
   margin-left: auto !important ;
   margin-right: auto !important;

  }
  .LanguageSelect{
    margin-left: 25% !important;
  }
  } */
</style>
